<template>
  <div>
    <p>{{ search }}</p>
    <div id="map" class="map"></div>
  </div>
</template>

<script>
var mapboxgl = require("mapbox-gl/dist/mapbox-gl.js");
export default {
  data() {
    return {
      showPopup: false,
      currentdata: [],
      center: [7.967886884831216, 48.098679623779674],
      zoom: 18,
      map: null,
      accessToken: 'pk.eyJ1IjoiZWxpYXM0NzExIiwiYSI6ImNrenhzd3h1azAzeXgyd3A5czJvZTJnZHAifQ.OiMDcMAHAWJOfaI2ZqoIHA',
    }
  },
  props: ["search"],
  methods: {
    createMap() {
      mapboxgl.accessToken = 'pk.eyJ1IjoiZWxpYXM0NzExIiwiYSI6ImNrenhzd3h1azAzeXgyd3A5czJvZTJnZHAifQ.OiMDcMAHAWJOfaI2ZqoIHA';
      this.map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: this.center,
        zoom: 10
      })
      if(this.$store.state.vergangeneLager.MarkerKoordinaten) {
        console.log(this.$store.state.vergangeneLager.MarkerKoordinaten)
        new mapboxgl.Marker().setLngLat(this.$store.state.vergangeneLager.MarkerKoordinaten).addTo(this.map);
        this.map.flyTo({
            center: this.$store.state.vergangeneLager.KartenMitte,
            zoom: this.zoom
        });
      } else {
        this.searchPlace();
      }
    },
    searchPlace() {
    const mapboxClient = mapboxSdk({ accessToken: this.accessToken });
      mapboxClient.geocoding
        .forwardGeocode({
            query: this.search,
            autocomplete: false,
            limit: 1
        })
        .send()
        .then((response) => {
        if (
          !response ||
          !response.body ||
          !response.body.features ||
          !response.body.features.length
        ) {
          console.error('Invalid response:');
          console.error(response);
          return;
        }
        const feature = response.body.features[0];
        
        // Create a marker and add it to the map.
        new mapboxgl.Marker().setLngLat(feature.center).addTo(this.map);
        this.map.flyTo({
            center: feature.center,
            zoom: this.zoom
        });
        this.$store.state.vergangeneLager.KartenMitte = feature.center;
        this.$store.state.vergangeneLager.MarkerKoordinaten = feature.center;
      });
    }
  },
  watch: {
    search() {
      this.searchPlace();
    }
  },
  mounted() {
    this.createMap();
  }
};
</script>

<style>
#map { 
  width: 100%;
  height: 500px;
}
</style>