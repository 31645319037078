<template>
  <v-container>
      <v-col cols="12">
          <h1> {{ data.Titel }} {{ data.Jahr }}</h1>
          <span><v-icon class="mr-2">mdi-map-marker-radius</v-icon>{{ data.Ort }}</span>
          <v-col class="mt-5">
              <v-textarea
              outlined
              label="Beschreibung"
              v-model="data.Beschreibung"
              >
              </v-textarea>
          </v-col>

          <h3>Sponsoren</h3>
          <v-col v-for="(sponsor, index) in data.Sponsoren" :key="index">
              <v-text-field
              outlined
              label="Sponsor"
              v-model="sponsor.Sponsor"
              ></v-text-field>
              <v-row>
                  <v-col cols="4">
                    <v-file-input
                    id="file"
                    v-model="sponsor.Logo"
                    outlined
                    truncate-length="15"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                    :disabled="!sponsor.Logo"
                    @click="uploadImage(index)"
                    color="primary"
                    >
                    Logo hochladen
                    </v-btn>
                  </v-col>
                  <v-col cols="4">
                      <v-img :src="sponsor.Logo" />
                  </v-col>
              </v-row>
              <div>
                  <v-btn 
                    color="red mr-3" 
                    dark
                    @click="removeSponsor(sponsor)"
                    >
                      Sponsor entfernen
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="addSponsor()"
                    >
                      Sponsor hinzufügen
                  </v-btn>
              </div>
              <hr class="my-5">
          </v-col>
          <v-col>
              <v-text-field
                outlined
                v-model="searchValue"
                label="Adresse Suchen"
                @keyup.enter="suche = searchValue"
                hint="Enter drücken zum Suchen"
                ></v-text-field>
              <Map :search="suche" width="100%" height="500px"/>
          </v-col>
          <v-col>
              <v-btn
              color="red"
              text
              @click="löschen()"
              >
                Löschen
              </v-btn>
              <v-btn
              color="primary"
              @click="save()"
              >
                Speichern
              </v-btn>
          </v-col>
      </v-col>
  </v-container>
</template>

<script>
import axios from 'axios'
import Map from '../components/Map.vue'

export default {
    components: {
        Map
    },
    data: () => {
        return {
            data: null,
            searchValue: '',
            suche: 'Kiefernweg 12, 79183 Waldkirch',
        }
    },
    methods: {
        getData() {
            axios
            .get('https://kjg-api.rezept-zettel.de/api/vergangeneLager/' + this.$route.params.id)
            .then(res => {
                this.data = res.data
                if(this.data.KartenMitte != []) {
                    this.$store.state.vergangeneLager.KartenMitte = this.data.KartenMitte
                }
                if(this.data.markerKoordinaten != []) {
                    this.$store.state.vergangeneLager.MarkerKoordinaten = this.data.MarkerKoordinaten
                }
                if(this.data.Sponsoren.length == 0) {
                    this.data.Sponsoren.push({
                        Sponsor: '',
                        Logo: null,
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
        },
        addSponsor() {
            this.data.Sponsoren.push({
                Sponsor: '',
                Logo: null,
            })
        },
        removeSponsor(index) {
            this.data.Sponsoren.splice(index, 1)
        },
        async uploadImage(index) {
            let data = document.getElementById('file').files[0]
            let formData = new FormData()
            formData.append('sponsorLogo', data)

            await axios
            .post('https://kjg-api.rezept-zettel.de/api/vergangeneLager/sponsor-logo', formData)
            .then(res => {
                this.data.Sponsoren[index].Logo = res.data.link
            })
            .catch(err => {
                console.log(err)
            })
        },
        save() {
            this.data.MarkerKoordinaten = this.$store.state.vergangeneLager.MarkerKoordinaten
            this.data.KartenMitte = this.$store.state.vergangeneLager.KartenMitte
            console.log(this.data)
            axios
            .patch('https://kjg-api.rezept-zettel.de/api/vergangeneLager/' + this.$route.params.id, this.data)
            .then(res => {
                console.log(res.data)
            })
            .catch(err => {
                console.log(err)
            })
        },
        löschen() {
            axios
            .delete('https://kjg-api.rezept-zettel.de/api/vergangeneLager/' + this.$route.params.id)
            .then(res => {
                this.$router.push('/lager')
            })
            .catch(err => {
                console.log(err)
            })
        }
    },
    beforeMount() {
        this.getData()
    }
}
</script>

<style>

</style>